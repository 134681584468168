//===========================================================================//
//                  [Highlight Panel Settings]                               //
//===========================================================================//
              $highlight-h3-font: $decorative-font;
             $highlight-h3-color: $color-primary;
       $highlight-h3-font-weight: $bold;
                   $highlight-fa: white;
             $highlight-bg-color: $color-quaternary;
                $highlight-color: $color-body;
          $highlight-font-weight: $light;

/*=========================================================================*/
/* [Highlight Panel Template Styles] */

.highlight-panel
{
    position: relative;
    padding: 60px 0;
    margin: -10px 0;
    background-color: $highlight-bg-color;
    
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    &:before
    {
        position: absolute;
        content: ' ';
        top: -5px;
        left: 60px;
        width: 410px;
        height: 288px;
        display: block;
        background: transparent url('../images/leaves-top.png') top center no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    
    &:after
    {
        position: absolute;
        content: ' ';
        bottom: -5px;
        right: 60px;
        width: 351px;
        height: 191px;
        display: block;
        background: transparent url('../images/leaves-bottom.png') top center no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    
    .text
    {
        position: relative;
        z-index: 1;
    
        color: $highlight-color;
        text-align: left;
        font-size: 24px;
        font-weight: $highlight-font-weight;
        
        h3
        {
            font-family: $highlight-h3-font;
            color: $highlight-h3-color;
            text-transform: uppercase;
        }
        
        .enquire-now
        {
            margin: 0 auto;
            padding-top: 10px;
        }
    }
    
    .fa
    {
        font-size: 60px;
        
        color: $highlight-fa;
    }
    
    h3
    {
        margin: 0 0 20px;
        font-weight: $highlight-h3-font-weight;
        font-family: $highlight-h3-font;
        color: $highlight-h3-color;
        font-size: 40px;
    }
    
    .testimonial
    {
        padding: 40px 0;
        color: $highlight-color;
        background-size: 300px 150px;
        
        .title
        {
            color: $highlight-color;
            font-size: 22px;
            font-weight: $regular;
            
            small
            {
                font-size: 17px;
            }
        }
    }
}

@media(min-width: 1200px) and (max-width: 1400px)
{
    .highlight-panel {
    
        &:before {
            left: 0;
            width: 410px;
            height: 288px;
        }
    
        &:after {
            right: 0;
            width: 351px;
            height: 191px;
        }
    }
}

@media(min-width: 768px) and (max-width: 1199px)
{
    .highlight-panel {
    
        &:before {
            left: -20px;
            width: 205px;
            height: 144px;
        }
    
        &:after {
            right: 0;
            width: 176px;
            height: 96px;
        }
    }
}

@media(max-width: 767px)
{
    .highlight-panel {
        
        &:before {
            left: -10px;
            width: 205px;
            height: 144px;
        }
        
        &:after {
            right: 0;
            width: 176px;
            height: 96px;
        }
    }
    
}


/* Large desktop */
@media (min-width: 1200px) {

}

/* Large desktop */
@media (max-width: 991px) {

}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {

}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Landscape phone to portrait tablet */
@media (max-width: 1024px) {
    .highlight-panel {
    
        .text {
            flex-basis: 100%;
            padding: 40px 0;
        
            text-align: center;
            font-size: 18px;
        }
    
        .action {
            flex-basis: 100%;
            text-align: center;
        
            .enrol-btn {
                position: relative;
                width: 460px;
                height: 250px;
                margin: 0 auto;
            }
        }
    }
}

/* Landscape phone to portrait tablet */
@media (max-width: 480px) {
    .highlight-panel {
    
        .text {
            padding: 20px 0;
            text-align: center;
            font-size: 16px;
        }
    
        .action {
        
            .enrol-btn {
                width: 230px;
                height: 125px;
            }
        }
    
        .testimonial {
            padding: 40px 20px 130px;
        }
    }
}