/*==========================================================================

	Template Elements Stylesheet
	____________________________________________________________

	Client:		Natural Beginnings Early Learning World
	Date:		15/11/2018
	Author:		Edge Marketing Solutions

===========================================================================*/

/* [Global Variables] - http://sass-lang.com/guide */

@import url('https://fonts.googleapis.com/css?family=Hind+Vadodara:400,500,700|Londrina+Solid:400');
				   $default-font: 'Hind Vadodara', Arial, cursive, sans-serif;
				$decorative-font: 'Londrina Solid', Arial, cursive, sans-serif;
						  $light: 400;
						$regular: 500;
						   $bold: 700;

/*===========================================================================*/
/*                        [Colour Settings]                                  */
/*===========================================================================*/
					 $color-body: #2E170A;
				  $color-primary: #CB521E;
				$color-secondary: #B9C743;
			     $color-tertiary: #D2AB67;
			   $color-quaternary: #FFF1D8;
				   $color-yellow: #F6C135;
				   $color-orange: #e2a174;
					 $color-pink: #f4a2c6;
			    $color-dark-grey: #414042;

/*===========================================================================*/
/*                 [Banner/Slider Settings]                                  */
/*===========================================================================*/
		   $banner-overlay-color: $color-secondary;
    $feature-image-overlay-color: transparent;
                 $slider-overlay: white;
				  $slider-border: $color-secondary;
             $slider-overlay-bar: rgba(255, 255, 255, 0.7);
     $slider-text-shadow-opacity: 1px 1px 1px rgba(0, 0, 0, 0.7);

//===========================================================================//
//                  [Button Settings]                                   //
//===========================================================================//
			     $btn-primary-bg: $color-secondary;
			  $btn-primary-color: $color-body;
		     $btn-primary-border: $color-primary;
  
			     $btn-success-bg: $color-body;
			  $btn-success-color: white;
		     $btn-success-border: $color-quaternary;
  
			     	$btn-info-bg: $color-secondary;
			  	 $btn-info-color: white;
		     	$btn-info-border: $color-secondary;


//===========================================================================//
//                  [Feature Nav Settings]                                   //
//===========================================================================//
		 $feature-nav-icon-color: white;
		$feature-nav-title-color: $color-dark-grey;
		 $feature-nav-text-color: $color-body;
	  		$feature-nav-a-color: $color-primary;
	  $feature-nav-a-color-hover: $color-tertiary;

/*=========================================================================*/

/* [Global Template Styles] */

body
{
	color: $color-body;
	font-family: $default-font;
	font-size: 15px;
}

h1
{
	margin: 0 0 20px 0;
	padding-top: 20px;
	background-size: 116px 44px;

	color: $color-primary;
	font-size: 46px;
	font-family: $decorative-font;
}

h2,
h2 a
{
	margin: 20px 0;

	font-family: $default-font;
	color: $color-tertiary;
	font-size: 32px;
}

h3
{
	margin: 20px 0;

	color: $color-primary;
	font-size: 22px;
}

h4
{
	color: $color-orange;
	font-size: 18px;
}

h5
{
	font-weight: bold;
	text-transform: uppercase;
	font-size: 13px;
}

p
{
	margin-bottom: 15px;
	line-height: 1.6em;
}

p.lead
{
	color: $color-orange;
	font-size: 24px;
}

.row-spaced
{
	margin-bottom: 30px;
}

a
{
	transition: all 150ms ease-in-out;
	color: $color-primary;
}

a:hover,
a:focus,
a:active {
	text-decoration: none;
	color: $color-secondary;
}

img
{
	max-width: 100%;
}

img.svg-responsive
{
	width: 100% \9;
}

img.img-lightbox
{
	cursor: zoom-in;
}

hr
{
	border-color: $color-tertiary;
	border-style: dashed;
}

/* Large desktop */
@media (min-width: 1200px) {

}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {

}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Landscape phone to portrait tablet */
@media (max-width: 768px) {

	//Bootstrap - hide only mobile
	.d-xs-none
	{
		display: none;
	}
}

@import "./partials/header";
@import "./partials/page";
@import "./partials/navigation";
@import "./partials/feature-navigation";
@import "./partials/buttons";
@import "./partials/slider";
@import "./partials/highlight-panel";
@import "./partials/social-icons";
@import "./partials/gallery";
@import "./partials/footer";
@import "./partials/overrides";