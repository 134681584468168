.gallery-images img
{
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    width: 100%;
}

.gallery-images .gallery-image-main::after
{
    position: absolute;
    bottom: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    content: ' ';
    z-index: 5;
    
    background: transparent url('../images/mag-glass.svg') no-repeat;
    background-size: cover;
}

.gallery-image-main
{
    padding-right: 10px!important;
}

.gallery-images .gallery-image-thumb
{
    padding: 0 10px 7px 0;
}