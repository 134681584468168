/*=========================================================================*/

/* [Social Icon Styles] */

/* Generated by Glyphter (http://www.glyphter.com) on  Tue Sep 06 2016*/
@font-face {
  font-family: 'social icons';
  src: url('../fonts/social-icons.eot');
  src: url('../fonts/social-icons.eot?#iefix') format('embedded-opentype'),
  url('../fonts/social-icons.woff') format('woff'),
  url('../fonts/social-icons.ttf') format('truetype'),
  url('../fonts/social-icons.svg#social-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="social-icon-"]::before, [class*=" social-icon-"]::before {
  display: inline-block;
  font-family: 'social icons';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.social-icon-square-facebook::before {content:'\0041';}
.social-icon-square-google-plus::before {content:'\0042';}
.social-icon-square-linkedin::before {content:'\0043';}
.social-icon-square-pinterest::before {content:'\0044';}
.social-icon-square-twitter::before {content:'\0045';}
.social-icon-square-youtube::before {content:'\0046';}
.social-icon-circle-facebook::before {content:'\0047';}
.social-icon-circle-google-plus::before {content:'\0048';}
.social-icon-circle-linkedin::before {content:'\0049';}
.social-icon-circle-pinterest::before {content:'\004a';}
.social-icon-circle-twitter::before {content:'\004b';}
.social-icon-circle-youtube::before {content:'\004c';}
.social-icon-rounded-facebook::before {content:'\004d';}
.social-icon-rounded-google-plus::before {content:'\004e';}
.social-icon-rounded-linkedin::before {content:'\004f';}
.social-icon-rounded-pinterest::before {content:'\0050';}
.social-icon-rounded-twitter::before {content:'\0051';}
.social-icon-rounded-youtube::before {content:'\0052';}

[class^="social-icon-circle-"]::before, [class*=" social-icon-circle-"]::before {
  border-radius: 100%;
}

[class^="social-icon-rounded-"]::before, [class*=" social-icon-rounded-"]::before {
  border-radius: 15%;
}