//=========================================================================//
// [Bootstrap Row Clear Fix Styles] //
#media-query-detector {
	display: none;
	width: 0;
}

@media (min-width: 768px) {
	#media-query-detector {
		width: 768px;
	}
}

@media (min-width: 992px) {
	#media-query-detector {
		width: 992px;
	}
}

@media (min-width: 1200px) {
	#media-query-detector {
		width: 1200px;
	}
}

.row-fix-clear-both {
	clear: both;
}

//=========================================================================//
// [Table as row override Styles] //
table.table-as-row > tbody > tr:before,
table.table-as-row > tbody > tr:after {
	content: " ";
	display: table;
	clear: both;
}

table.table-as-row > tbody > tr {
	height: auto !important;
}

table.table-as-row,
table.table-as-row > tbody,
table.table-as-row > tbody > tr {
	display: block;
	width: auto;
}

table.table-as-row > tbody > tr {
	display: block;
	width: auto;
	margin-right: -15px;
	margin-left: -15px;
}

table.table-as-row > tbody > tr > td[class*=col-] {
	display: block;
	height: auto !important;
	margin-bottom: 20px;
}

// col-lg //
@media (min-width: 1200px) {
	table.table-as-row > tbody > tr > td[class*=col-lg-] {
		float: left;
	}
}

// col-md //
@media (min-width: 992px) {
	table.table-as-row > tbody > tr > td[class*=col-md-] {
		float: left;
	}
}

// col-sm //
@media (min-width: 768px) {
	table.table-as-row > tbody > tr > td[class*=col-sm-] {
		float: left;
	}
}

// col-xs //
table.table-as-row > tbody > tr > td[class*=col-xs-] {
	float: left;
}