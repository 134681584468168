//=========================================================================//
// [Buttons Template Styles] //
//=========================================================================//
.btn
{
	transition: all 200ms ease-in-out;
	border-radius: 2px;
	border: none;
	font-weight: $bold;
	padding: 8px 20px;
	
	-webkit-box-shadow: 0 2px 0 0 rgba($color-body, 1);
	box-shadow: 0 2px 0 0 rgba($color-body, 1);
	
	&:hover {
		background-color: white !important;
		color: black !important;
		border-color: black !important;
	}
}

.btn-primary {
	background-color: $btn-primary-bg !important;
	border-color: $btn-primary-border !important;
	color: $btn-primary-color !important;
	
	&:not(:disabled):not(.disabled):active {
		background-color: darken($btn-primary-bg, 10) !important;
		border-color: darken($btn-primary-bg, 10) !important;
		color: white;
	}
	
	&:hover,
	&:focus,
	&.focus,
	&:active {
		box-shadow: 0 0 0 0.2rem rgba($btn-primary-bg, 0.5) !important;
		background-color: darken($btn-primary-bg, 10) !important;
		color: white;
	}
}

.btn-success {
	background-color: $btn-success-bg;
	border-color: darken($btn-success-bg, 15%);
	-webkit-box-shadow: 0 2px 0 0 rgba($color-primary, 1);
	box-shadow: 0 2px 0 0 rgba($color-primary, 1);
	
	&:not(:disabled):not(.disabled):active {
		background-color: darken($btn-success-bg, 10) !important;
		border-color: darken($btn-success-bg, 10) !important;
		color: white;
	}
	
	&:focus,
	&.focus,
	&:active {
		box-shadow: 0 0 0 0.2rem rgba($btn-success-bg, 0.5) !important;
	}
}

.btn-info {
	background-color: $btn-info-bg;
	border-color: darken($btn-info-bg, 15%);
	
	&:not(:disabled):not(.disabled):active {
		background-color: darken($btn-info-bg, 10) !important;
		border-color: darken($btn-info-bg, 10) !important;
		color: white;
	}
	
	&:focus,
	&.focus,
	&:active {
		box-shadow: 0 0 0 0.2rem rgba($btn-info-bg, 0.5) !important;
	}
}

.btn-pill
{
	padding: 10px 18px;
	
	border-radius: 2rem;
	
	font-size: 16px;
	font-family: $default-font;
	font-weight: 400;
	text-transform: uppercase;
	
	&:hover,
	&.show,
	&.active
	{
		background: black;
	}
}

.enquire-now
{
	max-width: 300px;
	padding-top: 40px;
	margin: 0 auto;
}

.btn-cloud {
	position: relative;
	display: block;
	max-width: 143px;
	max-height: 97px;
	padding: 28px 20px;
	font-size: 17px;
	font-weight: $bold;
	color: $color-secondary!important;
	text-align: center;
	line-height: 1.4rem;
	
	&.yellow
	{
		background: transparent url('../images/btn-cloud-yellow.svg') top left no-repeat;
		background-size: cover;
		
		&:hover,
		&:active,
		&:focus
		{
			color: $color-secondary;
			background: transparent url('../images/btn-cloud-pink.svg') top left no-repeat;
		}
	}
	
	&.pink
	{
		background: transparent url('../images/btn-cloud-pink.svg') top left no-repeat;
		background-size: cover;
		
		&:hover,
		&:active,
		&:focus
		{
			background: transparent url('../images/btn-cloud-yellow.svg') top left no-repeat;
			color: $color-secondary;
		}
	}
	
	&.inline
	{
		display: inline-block!important;
	}
}


