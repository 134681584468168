/*==========================================================================

	Template Elements Stylesheet
	____________________________________________________________

	Client:		Natural Beginnings Early Learning World
	Date:		15/11/2018
	Author:		Edge Marketing Solutions

===========================================================================*/
/* [Global Variables] - http://sass-lang.com/guide */
@import url("https://fonts.googleapis.com/css?family=Hind+Vadodara:400,500,700|Londrina+Solid:400");
/*===========================================================================*/
/*                        [Colour Settings]                                  */
/*===========================================================================*/
/*===========================================================================*/
/*                 [Banner/Slider Settings]                                  */
/*===========================================================================*/
/*=========================================================================*/
/* [Global Template Styles] */
body {
  color: #2E170A;
  font-family: "Hind Vadodara", Arial, cursive, sans-serif;
  font-size: 15px;
}

h1 {
  margin: 0 0 20px 0;
  padding-top: 20px;
  background-size: 116px 44px;
  color: #CB521E;
  font-size: 46px;
  font-family: "Londrina Solid", Arial, cursive, sans-serif;
}

h2,
h2 a {
  margin: 20px 0;
  font-family: "Hind Vadodara", Arial, cursive, sans-serif;
  color: #D2AB67;
  font-size: 32px;
}

h3 {
  margin: 20px 0;
  color: #CB521E;
  font-size: 22px;
}

h4 {
  color: #e2a174;
  font-size: 18px;
}

h5 {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
}

p {
  margin-bottom: 15px;
  line-height: 1.6em;
}

p.lead {
  color: #e2a174;
  font-size: 24px;
}

.row-spaced {
  margin-bottom: 30px;
}

a {
  transition: all 150ms ease-in-out;
  color: #CB521E;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #B9C743;
}

img {
  max-width: 100%;
}

img.svg-responsive {
  width: 100% \9;
}

img.img-lightbox {
  cursor: zoom-in;
}

hr {
  border-color: #D2AB67;
  border-style: dashed;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
@media (max-width: 768px) {
  .d-xs-none {
    display: none;
  }
}

/*=========================================================================*/
/* [Header Template Styles] */
.header {
  position: relative;
  width: 100%;
  z-index: 20;
  padding: 0 0 20px 0;
  margin-bottom: -95px;
}

.header .h-logo {
  max-width: 251px;
}

.header .h-logo img {
  width: 100%;
}

.header .btn-cloud {
  float: right;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
@media (max-width: 991px) {
  .header {
    text-align: center;
    padding: 20px 0 0 0;
    margin-bottom: 20px;
  }
  .header .h-logo img {
    max-width: 280px;
    margin-bottom: 10px;
  }
  .header .btn-cloud {
    float: none;
    display: block;
    width: 145px;
    height: 100px;
    margin: 20px auto !important;
  }
}

/*=========================================================================*/
/* [Page Template Styles] */
.page {
  width: 100%;
  min-height: 50vh;
  padding: 30px 0;
  position: relative;
}

.page:before {
  position: absolute;
  content: ' ';
  top: -15px;
  right: 60px;
  width: 171px;
  height: 120px;
  display: block;
  background: transparent url("../images/leaves-top.png") top center no-repeat;
  background-size: cover;
  overflow: hidden;
}

.page.page-homepage {
  padding: 0;
  min-height: auto;
}

.page.page-homepage:before {
  background: none !important;
}

.page.page-homepage .page-contents {
  padding: 0;
  display: flex;
  position: relative;
}

.page.page-homepage .page-contents .lead {
  color: #B9C743;
  line-height: 1.3em;
}

.page.page-homepage .homepage-image {
  flex-basis: 30%;
  position: relative;
  z-index: 0;
  background-size: cover;
}

.page.page-homepage .homepage-content {
  flex-basis: 70%;
  padding: 120px;
  position: relative;
  background: #D2AB67;
  color: white;
  font-size: 15px;
}

.page.page-homepage .homepage-content .lead {
  color: white;
  line-height: 1.3em;
}

.page.page-homepage .homepage-content h1 {
  color: white;
  font-family: "Londrina Solid", Arial, cursive, sans-serif;
  font-weight: 400;
  font-size: 42px;
  background: none;
  padding: 0;
}

.subpage-banner {
  z-index: 1;
  position: relative;
}

.subpage-banner:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: ' ';
  background: rgba(246, 193, 53, 0.7);
}

.border-nb {
  position: relative;
  width: 100%;
  display: block;
  height: 21px;
  clear: both;
  background: transparent url("../images/border.svg") top center repeat-y;
  background-size: cover;
  z-index: 10;
}

.border-nb.top, .border-nb.bottom-reversed {
  bottom: -10px;
}

.border-nb.bottom, .border-nb.top-reversed {
  top: -10px;
}

.border-nb.no-margin {
  margin: 0 !important;
}

/* Large desktop */
/* Large desktop */
@media (max-width: 991px) {
  .page-homepage .homepage-image:before {
    background: none;
  }
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .page.page-homepage .homepage-image {
    flex-basis: 15%;
  }
  .page.page-homepage .homepage-content {
    flex-basis: 85%;
  }
}

/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
@media (max-width: 1024px) {
  .page {
    padding: 40px 6px;
  }
  .page.page-homepage {
    padding: 0;
  }
  .page.page-homepage .page-contents {
    padding: 0;
    display: block;
  }
  .page.page-homepage .homepage-image {
    width: 100%;
    height: 250px;
  }
  .page.page-homepage .homepage-content {
    width: 100%;
    padding: 30px;
    font-size: 18px;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .homepage-contents {
    padding: 0;
    display: block;
  }
  .homepage-contents .homepage-image {
    width: 100%;
    height: 250px;
  }
  .homepage-contents .homepage-text {
    width: 100%;
    padding: 80px 30px 30px 30px;
    font-size: 18px;
  }
}

@media (max-width: 425px) {
  .page:before {
    background: none;
  }
}

/*=========================================================================*/
/* [Navigation Template Styles] */
.navbar-dark {
  padding: 30px 0 0 0;
}

.navbar-dark .navbar-nav {
  width: 100%;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: #B9C743;
}

.navbar-dark .nav-item > .nav-link {
  padding: 0 25px;
  font-size: 1.5rem;
  font-family: "Londrina Solid", Arial, cursive, sans-serif;
  font-weight: 400;
  color: #CB521E;
}

.navbar-dark .nav-item > .nav-link:hover, .navbar-dark .nav-item > .nav-link:focus {
  color: #B9C743;
}

.navbar-dark .nav-item:first-child .nav-link {
  padding-left: 0;
}

.navbar-dark .nav-item:last-child .nav-link {
  padding-right: 0;
}

.navbar-dark .nav-item.active {
  color: #B9C743;
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show {
  color: #B9C743;
}

.navbar-dark .navbar-toggler {
  width: 100%;
  padding: 20px 5px;
  background-color: #CB521E;
  border-radius: 0;
  border: none;
  color: white;
}

.navbar-dark .h-facebook > .nav-link {
  margin-top: -10px;
  padding: 0 0 0 10px;
  font-size: 30px;
  color: #B9C743;
}

.navbar-dark .h-facebook > .nav-link:hover, .navbar-dark .h-facebook > .nav-link:active, .navbar-dark .h-facebook > .nav-link:focus {
  color: #CB521E;
}

.nav > .nav-item.active > a {
  color: #B9C743;
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .navbar-dark .nav-item > .nav-link {
    font-size: 1.2rem;
  }
}

@media (min-width: 991px) {
  .navbar-dark .navbar-nav .dropdown-menu {
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    -webkit-box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  }
  .navbar-dark .dropdown-item {
    font-size: 14px;
  }
  .navbar-dark .dropdown-item.active,
  .navbar-dark .dropdown-item:active {
    background-color: #CB521E;
    color: white;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 991px) {
  .navbar-dark {
    padding: 0;
    background: #CB521E;
    margin-top: 20px !important;
  }
  .navbar-dark .navbar-nav {
    display: block;
    border: 1px solid #CB521E;
  }
  .navbar-dark .nav-item > .nav-link {
    padding: 10px 0;
    font-size: 17px;
    text-align: center;
    color: white;
  }
  .navbar-nav .dropdown-menu {
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 20px 1px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 20px 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-nav .dropdown-item {
    text-align: center;
  }
}

/* small */
/*=========================================================================*/
/* [Feature Button Styles] */
.feature-nav {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin: 0;
}

.feature-nav .feature-nav-item {
  flex-basis: 33.33%;
  position: relative;
  z-index: 1;
  padding: 40px 20px;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: left;
  font-size: 28px;
}

.feature-nav .feature-nav-item i {
  color: white;
  margin-top: 43px;
  margin-left: 3px;
}

.feature-nav .feature-nav-item p {
  color: #2E170A;
  font-family: "Hind Vadodara", Arial, cursive, sans-serif;
  font-size: 15px;
}

.feature-nav .feature-nav-item a.title {
  font-size: 28px;
  font-family: "Londrina Solid", Arial, cursive, sans-serif;
  color: #CB521E;
  text-transform: uppercase;
}

.feature-nav .feature-nav-item a.title:hover {
  color: #D2AB67;
}

.feature-nav .feature-nav-item a:hover:before {
  background-color: rgba(0, 0, 0, 0);
}

.feature-nav .feature-nav-item a .content {
  width: 100%;
}

.feature-nav .feature-nav-item a img {
  max-width: 70%;
  max-height: 110px;
}

.feature-nav .feature-nav-icon {
  width: 150px;
  height: 150px;
  display: block;
  margin: 0 auto 20px;
  font-size: 60px;
  background-size: cover;
}

.feature-nav .feature-nav-icon.icon_0 {
  background: transparent url("/images/feature-btn-services.svg") top center no-repeat;
}

.feature-nav .feature-nav-icon.icon_1 {
  background: transparent url("/images/feature-btn-about-us.svg") top center no-repeat;
}

.feature-nav .feature-nav-icon.icon_2 {
  background: transparent url("/images/feature-btn-events.svg") top center no-repeat;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .feature-nav .feature-nav-item {
    flex-basis: 50%;
    font-size: 24px;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 991px) {
  .feature-nav .feature-nav-item {
    text-align: center;
  }
  .feature-nav .feature-nav-item img {
    max-width: 70%;
    max-height: 90px;
  }
  .feature-nav .feature-nav-item p {
    font-size: 13px;
    line-height: 1.5em;
    width: 100%;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 768px) {
  .feature-nav {
    padding: 20px;
  }
  .feature-nav .feature-nav-item {
    flex-basis: 100%;
    min-height: 130px;
    margin-bottom: 10px;
    font-size: 24px;
    text-align: center;
  }
  .feature-nav img {
    max-width: 70%;
    max-height: 90px;
  }
  .feature-nav p {
    font-size: 13px;
    line-height: 1.5em;
    width: 100%;
  }
}

.btn {
  transition: all 200ms ease-in-out;
  border-radius: 2px;
  border: none;
  font-weight: 700;
  padding: 8px 20px;
  -webkit-box-shadow: 0 2px 0 0 #2e170a;
  box-shadow: 0 2px 0 0 #2e170a;
}

.btn:hover {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
}

.btn-primary {
  background-color: #B9C743 !important;
  border-color: #CB521E !important;
  color: #2E170A !important;
}

.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #99a631 !important;
  border-color: #99a631 !important;
  color: white;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary.focus, .btn-primary:active {
  box-shadow: 0 0 0 0.2rem rgba(185, 199, 67, 0.5) !important;
  background-color: #99a631 !important;
  color: white;
}

.btn-success {
  background-color: #2E170A;
  border-color: black;
  -webkit-box-shadow: 0 2px 0 0 #cb521e;
  box-shadow: 0 2px 0 0 #cb521e;
}

.btn-success:not(:disabled):not(.disabled):active {
  background-color: #040201 !important;
  border-color: #040201 !important;
  color: white;
}

.btn-success:focus, .btn-success.focus, .btn-success:active {
  box-shadow: 0 0 0 0.2rem rgba(46, 23, 10, 0.5) !important;
}

.btn-info {
  background-color: #B9C743;
  border-color: #87922b;
}

.btn-info:not(:disabled):not(.disabled):active {
  background-color: #99a631 !important;
  border-color: #99a631 !important;
  color: white;
}

.btn-info:focus, .btn-info.focus, .btn-info:active {
  box-shadow: 0 0 0 0.2rem rgba(185, 199, 67, 0.5) !important;
}

.btn-pill {
  padding: 10px 18px;
  border-radius: 2rem;
  font-size: 16px;
  font-family: "Hind Vadodara", Arial, cursive, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}

.btn-pill:hover, .btn-pill.show, .btn-pill.active {
  background: black;
}

.enquire-now {
  max-width: 300px;
  padding-top: 40px;
  margin: 0 auto;
}

.btn-cloud {
  position: relative;
  display: block;
  max-width: 143px;
  max-height: 97px;
  padding: 28px 20px;
  font-size: 17px;
  font-weight: 700;
  color: #B9C743 !important;
  text-align: center;
  line-height: 1.4rem;
}

.btn-cloud.yellow {
  background: transparent url("../images/btn-cloud-yellow.svg") top left no-repeat;
  background-size: cover;
}

.btn-cloud.yellow:hover, .btn-cloud.yellow:active, .btn-cloud.yellow:focus {
  color: #B9C743;
  background: transparent url("../images/btn-cloud-pink.svg") top left no-repeat;
}

.btn-cloud.pink {
  background: transparent url("../images/btn-cloud-pink.svg") top left no-repeat;
  background-size: cover;
}

.btn-cloud.pink:hover, .btn-cloud.pink:active, .btn-cloud.pink:focus {
  background: transparent url("../images/btn-cloud-yellow.svg") top left no-repeat;
  color: #B9C743;
}

.btn-cloud.inline {
  display: inline-block !important;
}

.swiper-wrapper {
  position: relative;
  z-index: 1;
}

.swiper-slide {
  z-index: 1;
}

.swiper-slide img {
  width: 100%;
}

.swiper-container,
.swiper-container.rotator {
  position: relative;
  overflow: hidden;
}

.swiper-container .overlay,
.swiper-container.rotator .overlay {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40vw;
  opacity: 0.7;
  z-index: 10;
}

/* Large desktop */
/* Desktop */
@media (min-width: 992px) {
  .swiper-text {
    position: absolute;
    top: 100px;
    right: 200px;
    color: white;
    font-family: "Hind Vadodara", Arial, cursive, sans-serif;
    font-size: 70px;
    font-weight: 700;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.33);
    z-index: 10;
  }
}

@media (max-width: 1024px) {
  .swiper-text {
    position: relative;
    color: white;
  }
  .swiper-text .title {
    font-size: 17px;
  }
  .swiper-text .subtitle {
    font-size: 15px;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 991px) {
  .swiper-slide:after {
    display: none;
  }
}

/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .swiper-container .swiper-wrapper::before {
    width: 200px;
    height: 120px;
  }
}

/*=========================================================================*/
/* [Highlight Panel Template Styles] */
.highlight-panel {
  position: relative;
  padding: 60px 0;
  margin: -10px 0;
  background-color: #FFF1D8;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.highlight-panel:before {
  position: absolute;
  content: ' ';
  top: -5px;
  left: 60px;
  width: 410px;
  height: 288px;
  display: block;
  background: transparent url("../images/leaves-top.png") top center no-repeat;
  background-size: cover;
  overflow: hidden;
}

.highlight-panel:after {
  position: absolute;
  content: ' ';
  bottom: -5px;
  right: 60px;
  width: 351px;
  height: 191px;
  display: block;
  background: transparent url("../images/leaves-bottom.png") top center no-repeat;
  background-size: cover;
  overflow: hidden;
}

.highlight-panel .text {
  position: relative;
  z-index: 1;
  color: #2E170A;
  text-align: left;
  font-size: 24px;
  font-weight: 400;
}

.highlight-panel .text h3 {
  font-family: "Londrina Solid", Arial, cursive, sans-serif;
  color: #CB521E;
  text-transform: uppercase;
}

.highlight-panel .text .enquire-now {
  margin: 0 auto;
  padding-top: 10px;
}

.highlight-panel .fa {
  font-size: 60px;
  color: white;
}

.highlight-panel h3 {
  margin: 0 0 20px;
  font-weight: 700;
  font-family: "Londrina Solid", Arial, cursive, sans-serif;
  color: #CB521E;
  font-size: 40px;
}

.highlight-panel .testimonial {
  padding: 40px 0;
  color: #2E170A;
  background-size: 300px 150px;
}

.highlight-panel .testimonial .title {
  color: #2E170A;
  font-size: 22px;
  font-weight: 500;
}

.highlight-panel .testimonial .title small {
  font-size: 17px;
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .highlight-panel:before {
    left: 0;
    width: 410px;
    height: 288px;
  }
  .highlight-panel:after {
    right: 0;
    width: 351px;
    height: 191px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .highlight-panel:before {
    left: -20px;
    width: 205px;
    height: 144px;
  }
  .highlight-panel:after {
    right: 0;
    width: 176px;
    height: 96px;
  }
}

@media (max-width: 767px) {
  .highlight-panel:before {
    left: -10px;
    width: 205px;
    height: 144px;
  }
  .highlight-panel:after {
    right: 0;
    width: 176px;
    height: 96px;
  }
}

/* Large desktop */
/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
@media (max-width: 1024px) {
  .highlight-panel .text {
    flex-basis: 100%;
    padding: 40px 0;
    text-align: center;
    font-size: 18px;
  }
  .highlight-panel .action {
    flex-basis: 100%;
    text-align: center;
  }
  .highlight-panel .action .enrol-btn {
    position: relative;
    width: 460px;
    height: 250px;
    margin: 0 auto;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 480px) {
  .highlight-panel .text {
    padding: 20px 0;
    text-align: center;
    font-size: 16px;
  }
  .highlight-panel .action .enrol-btn {
    width: 230px;
    height: 125px;
  }
  .highlight-panel .testimonial {
    padding: 40px 20px 130px;
  }
}

/*=========================================================================*/
/* [Social Icon Styles] */
/* Generated by Glyphter (http://www.glyphter.com) on  Tue Sep 06 2016*/
@font-face {
  font-family: 'social icons';
  src: url("../fonts/social-icons.eot");
  src: url("../fonts/social-icons.eot?#iefix") format("embedded-opentype"), url("../fonts/social-icons.woff") format("woff"), url("../fonts/social-icons.ttf") format("truetype"), url("../fonts/social-icons.svg#social-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="social-icon-"]::before, [class*=" social-icon-"]::before {
  display: inline-block;
  font-family: 'social icons';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.social-icon-square-facebook::before {
  content: '\0041';
}

.social-icon-square-google-plus::before {
  content: '\0042';
}

.social-icon-square-linkedin::before {
  content: '\0043';
}

.social-icon-square-pinterest::before {
  content: '\0044';
}

.social-icon-square-twitter::before {
  content: '\0045';
}

.social-icon-square-youtube::before {
  content: '\0046';
}

.social-icon-circle-facebook::before {
  content: '\0047';
}

.social-icon-circle-google-plus::before {
  content: '\0048';
}

.social-icon-circle-linkedin::before {
  content: '\0049';
}

.social-icon-circle-pinterest::before {
  content: '\004a';
}

.social-icon-circle-twitter::before {
  content: '\004b';
}

.social-icon-circle-youtube::before {
  content: '\004c';
}

.social-icon-rounded-facebook::before {
  content: '\004d';
}

.social-icon-rounded-google-plus::before {
  content: '\004e';
}

.social-icon-rounded-linkedin::before {
  content: '\004f';
}

.social-icon-rounded-pinterest::before {
  content: '\0050';
}

.social-icon-rounded-twitter::before {
  content: '\0051';
}

.social-icon-rounded-youtube::before {
  content: '\0052';
}

[class^="social-icon-circle-"]::before, [class*=" social-icon-circle-"]::before {
  border-radius: 100%;
}

[class^="social-icon-rounded-"]::before, [class*=" social-icon-rounded-"]::before {
  border-radius: 15%;
}

.gallery-images img {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  width: 100%;
}

.gallery-images .gallery-image-main::after {
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  content: ' ';
  z-index: 5;
  background: transparent url("../images/mag-glass.svg") no-repeat;
  background-size: cover;
}

.gallery-image-main {
  padding-right: 10px !important;
}

.gallery-images .gallery-image-thumb {
  padding: 0 10px 7px 0;
}

/*=========================================================================*/
/* [Footer Template Styles] */
.footer {
  width: 100%;
  padding: 55px 0;
  background-color: #D2AB67;
  color: white;
  font-size: 13px;
}

.footer a {
  color: white;
}

.footer a:hover {
  color: #2e170a;
  text-decoration: none;
}

.footer .btn {
  vertical-align: top;
}

.footer h6 {
  margin: 0 0 5px 0;
  color: #CB521E;
  font-size: 20px;
  font-weight: bold;
  font-family: "Londrina Solid", Arial, cursive, sans-serif;
}

.footer ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer ul > li {
  padding: 8px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .f-actions {
  text-align: right;
  margin-top: 40px;
}

.footer .f-actions i {
  position: relative;
  top: 10px;
  font-size: 40px;
  padding-left: 10px;
  margin-top: 10px;
  color: #2E170A;
}

.footer .f-actions i:hover {
  color: #2E170A;
}

.footer .footer-info {
  font-family: "Londrina Solid", Arial, cursive, sans-serif;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.2rem;
}

.footer .btn-social {
  padding: 0 14px;
  display: inline-block;
  margin: 0 10px;
  background-color: white;
  border-radius: 50%;
  font-size: 30px;
  text-align: center;
}

.footer .footnotes {
  padding-top: 30px;
  opacity: 0.3;
  font-size: 12px;
}

.footer .fb-page {
  overflow: hidden;
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .footer .btn-social {
    margin: 10px;
  }
  .footer .f-hours strong {
    display: block;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .footer {
    padding: 20px 10px 50px 10px;
    text-align: center;
  }
  .footer h6 {
    margin: 30px 0 5px 0;
    color: white;
    font-weight: bold;
  }
  .footer .f-actions {
    padding-top: 40px;
    text-align: center;
  }
}

#media-query-detector {
  display: none;
  width: 0;
}

@media (min-width: 768px) {
  #media-query-detector {
    width: 768px;
  }
}

@media (min-width: 992px) {
  #media-query-detector {
    width: 992px;
  }
}

@media (min-width: 1200px) {
  #media-query-detector {
    width: 1200px;
  }
}

.row-fix-clear-both {
  clear: both;
}

table.table-as-row > tbody > tr:before,
table.table-as-row > tbody > tr:after {
  content: " ";
  display: table;
  clear: both;
}

table.table-as-row > tbody > tr {
  height: auto !important;
}

table.table-as-row,
table.table-as-row > tbody,
table.table-as-row > tbody > tr {
  display: block;
  width: auto;
}

table.table-as-row > tbody > tr {
  display: block;
  width: auto;
  margin-right: -15px;
  margin-left: -15px;
}

table.table-as-row > tbody > tr > td[class*=col-] {
  display: block;
  height: auto !important;
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  table.table-as-row > tbody > tr > td[class*=col-lg-] {
    float: left;
  }
}

@media (min-width: 992px) {
  table.table-as-row > tbody > tr > td[class*=col-md-] {
    float: left;
  }
}

@media (min-width: 768px) {
  table.table-as-row > tbody > tr > td[class*=col-sm-] {
    float: left;
  }
}

table.table-as-row > tbody > tr > td[class*=col-xs-] {
  float: left;
}
