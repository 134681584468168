/*=========================================================================*/

/* [Navigation Template Styles] */

.navbar-dark
{
	padding: 30px 0 0 0;
	
	.navbar-nav
	{
		width: 100%;
		
		.nav-link:hover,
		.nav-link:focus
		{
			color: $color-secondary;
		}
	}
	
	.nav-item
	{
		> .nav-link
		{
			padding: 0 25px;
			
			font-size: 1.5rem;
			font-family: $decorative-font;
			font-weight: $light;
			color: $color-primary;
			
			&:hover,
			&:focus
			{
				color: $color-secondary;
			}
		}
		
		&:first-child .nav-link
		{
			padding-left: 0;
		}
		
		&:last-child .nav-link
		{
			padding-right: 0;
		}
		
		&.active
		{
			color: $color-secondary;
		}
	}
}

	.navbar-dark .navbar-nav .show > .nav-link,
	.navbar-dark .navbar-nav .active > .nav-link,
	.navbar-dark .navbar-nav .nav-link.show
	{
		color: $color-secondary;
	}

	.navbar-dark .navbar-toggler
	{
		width: 100%;
		padding: 20px 5px;

		background-color: $color-primary;
		border-radius: 0;
		border: none;

		color: rgb(255, 255, 255);
	}

	.navbar-dark .h-facebook > .nav-link
	{
		margin-top: -10px;
		padding: 0 0 0 10px;
		font-size: 30px;
		color: $color-secondary;
		
		&:hover,
		&:active,
		&:focus
		{
			color: $color-primary;
		}
	}
	

.nav > .nav-item.active > a
{
	color: $color-secondary;
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
	.navbar-dark {
		
		.nav-item {
			> .nav-link {
				font-size: 1.2rem;
			}
		}
	}
}

@media (min-width: 991px) and (max-width: 1023px) {

}

@media (min-width: 991px)
{
	.navbar-dark .navbar-nav .dropdown-menu
	{
		left: 50%;
		transform: translateX(-50%);
		text-align: center;

		-webkit-box-shadow: 3px 3px 10px 0 rgba(0,0,0,0.2);
		box-shadow: 3px 3px 10px 0 rgba(0,0,0,0.2);
	}
	.navbar-dark .dropdown-item
	{
		font-size: 14px;
	}

	.navbar-dark .dropdown-item.active,
	.navbar-dark .dropdown-item:active
	{
		background-color: $color-primary;

		color: rgb(255, 255, 255);
	}

}

/* Landscape phone to portrait tablet */
@media (max-width: 991px) {

	.navbar-dark
	{
		padding: 0;
		background: $color-primary;
		margin-top: 20px!important;
	}

	.navbar-dark .navbar-nav
	{
		display: block;

		border: 1px solid $color-primary;
	}

	.navbar-dark .nav-item > .nav-link
	{
		padding: 10px 0;

		font-size: 17px;

		text-align: center;

		color: white;
	}

	.navbar-nav .dropdown-menu
	{
		border-radius: 0;
		-webkit-box-shadow:inset 0 0 20px 1px rgba(0,0,0,0.1);
		box-shadow:inset 0 0 20px 1px rgba(0,0,0,0.1);
	}

		.navbar-nav .dropdown-item
		{
			text-align: center;
		}
}

/* small */
@media (max-width: 767px) {

}