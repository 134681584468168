.swiper-wrapper
{
	position: relative;
	z-index: 1;
}

.swiper-slide
{
	z-index: 1;
	
	img
	{
		width: 100%;
	}
}

.swiper-container,
.swiper-container.rotator {
	position: relative;
	overflow: hidden;
	
	.overlay {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 40vw;
		opacity: 0.7;
		z-index: 10;
	}
}

/* Large desktop */
@media (min-width: 1200px) {

}

/* Desktop */
@media (min-width: 992px) {
	.swiper-text
	{
		position: absolute;
		top: 100px;
		right: 200px;
		color: white;
		font-family: $default-font;
		font-size: 70px;
		font-weight: 700;
		text-shadow: 0 0 5px rgba(0,0,0,0.33);
		z-index: 10;
	}
}

@media(max-width: 1024px) {
	.swiper-text
	{
		position: relative;
		color: rgb(255, 255, 255);
		
		.title
		{
			font-size: 17px;
		}
		
		.subtitle
		{
			font-size: 15px;
		}
	}
}

/* Landscape phone to portrait tablet */
@media (max-width: 991px) {
	.swiper-slide:after
	{
		display: none;
	}
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
	.swiper-container {
		.swiper-wrapper {
			&::before {
				width: 200px;
				height: 120px;
			}
		}
	}
}