/*=========================================================================*/
/* [Page Template Styles] */

.page
{
    width: 100%;
    min-height: 50vh;
    padding: 30px 0;
    position: relative;
    
    &:before
    {
        position: absolute;
        content: ' ';
        top: -15px;
        right: 60px;
        width: 171px;
        height: 120px;
        display: block;
        background: transparent url('../images/leaves-top.png') top center no-repeat;
        background-size: cover;
        overflow: hidden;
    }
    
    &.page-homepage
    {
        padding: 0;
        min-height: auto;
    
        &:before
        {
            background: none!important;
        }
    
        .page-contents
        {
            padding: 0;
            display: flex;
            position: relative;
            
            .lead
            {
                color: $color-secondary;
                line-height: 1.3em;
            }
        }
    
        .homepage-image
        {
            flex-basis: 30%;
            position: relative;
            z-index: 0;
            background-size: cover;
        }
    
        .homepage-content
        {
            flex-basis: 70%;
            padding: 120px;
            position: relative;
        
            background: $color-tertiary;
            color: white;
            font-size: 15px;
    
            .lead
            {
                color: white;
                line-height: 1.3em;
            }
        
            h1
            {
                color: white;
                font-family: $decorative-font;
                font-weight: $light;
                font-size: 42px;
                background: none;
                padding: 0;
            }
        }
    }
}

.subpage-banner {
    z-index: 1;
    position: relative;
    
    &:after
    {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: ' ';
        background: rgba($color-yellow, 0.7)
    }
}

.border-nb
{
    position: relative;
    width: 100%;
    display: block;
    height: 21px;
    clear: both;
    background: transparent url('../images/border.svg') top center repeat-y;
    background-size: cover;
    z-index: 10;
    
    &.top,
    &.bottom-reversed
    {
        bottom: -10px;
    }
    
    &.bottom,
    &.top-reversed
    {
        top: -10px;
    }
    
    &.no-margin
    {
        margin: 0!important;
    }
}

/* Large desktop */
@media (min-width: 1200px) {

}

/* Large desktop */
@media (max-width: 991px) {
    .page-homepage .homepage-image:before
    {
        background: none;
    }
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
    .page
    {
        &.page-homepage
        {
            .homepage-image
            {
                flex-basis: 15%;
            }
            .homepage-content
            {
                flex-basis: 85%;
            }
        }
    }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Landscape phone to portrait tablet */
@media (max-width: 1024px) {
    .page
    {
        padding: 40px 6px;
        
        &.page-homepage {
            padding: 0;
    
            .page-contents
            {
                padding: 0;
                display: block;
            }
    
            .homepage-image
            {
                width: 100%;
                height: 250px;
            }
    
            .homepage-content
            {
                width: 100%;
                padding: 30px;
        
                font-size: 18px;
            }
        }
    }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
    
    .homepage-contents
    {
        padding: 0;
        display: block;
    
        .homepage-image
        {
            width: 100%;
            height: 250px;
        }
    
        .homepage-text
        {
            width: 100%;
            padding: 80px 30px 30px 30px;
        
            font-size: 18px;
        }
    }

}

@media (max-width: 425px)
{
    .page
    {
        &:before
        {
            background: none;
        }
    }
}