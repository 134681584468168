//===========================================================================//
//                        [Footer Settings]                                  //
//===========================================================================//
						$footer-bg: $color-tertiary;
					 $footer-color: white;
				   $footer-a-color: $footer-color;
				   $footer-a-hover: rgba($color-body, 1);
					   $row-social: $color-body;
				 $row-social-hover: $color-body;
				 		$footer-h6: $color-primary;

/*=========================================================================*/

/* [Footer Template Styles] */

.footer
{
	width: 100%;
	padding: 55px 0;
	
	background-color: $footer-bg;
	
	color: $footer-color;
	font-size: 13px;
	
	a
	{
		color: $footer-a-color;
		
		&:hover
		{
			color: $footer-a-hover;
			text-decoration: none;
		}
	}
	
	.btn
	{
		vertical-align: top;
	}
	
	h6
	{
		margin: 0 0 5px 0;
		
		color: $footer-h6;
		font-size: 20px;
		font-weight: bold;
		font-family: $decorative-font;
	}
	
	ul
	{
		margin: 0;
		padding: 0;
		
		list-style-type: none;
		
		> li
		{
			padding: 8px 0;
			border-top: 1px solid rgba(255, 255, 255, 0.1);
		}
	}
	
	.f-actions {
		text-align: right;
		margin-top: 40px;
		
		i {
			position: relative;
			top: 10px;
			font-size: 40px;
			padding-left: 10px;
			margin-top: 10px;
			color: $row-social;
			
			&:hover {
				color: $row-social-hover;
			}
		}
	}
	
	.footer-info
	{
		font-family: $decorative-font;
		text-align: center;
		font-size: 1.4rem;
		line-height: 1.2rem;
	}
		
	.btn-social
	{
		padding: 0 14px;
		display: inline-block;
		margin: 0 10px;
		
		background-color: rgb(255, 255, 255);
		border-radius: 50%;
		
		font-size: 30px;
		text-align: center;
	}
	
	.footnotes
	{
		padding-top: 30px;
		
		opacity: 0.3;
		
		font-size: 12px;
	}
	
	.fb-page
	{
		overflow: hidden;
	}
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
	.footer {
		.btn-social
		{
			margin: 10px;
		}
		
		.f-hours strong
		{
			display: block;
		}
	}
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
	.footer
	{
		padding: 20px 10px 50px 10px;
		
		text-align: center;
		
		h6
		{
			margin: 30px 0 5px 0;
			
			color: white;
			font-weight: bold;
		}
		
		.f-actions
		{
			padding-top: 40px;
			text-align: center;
		}
	}
}