/*=========================================================================*/

/* [Feature Button Styles] */

.feature-nav
{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin: 0;
    
    .feature-nav-item
    {
        flex-basis: 33.33%;
        position: relative;
        z-index: 1;
        padding: 40px 20px;
        
        background-size: cover;
        background-repeat: no-repeat;
        
        text-align: left;
        font-size: 28px;
    
        i
        {
            color: $feature-nav-icon-color;
            margin-top: 43px;
            margin-left: 3px;
        }
    
        p
        {
            color: $feature-nav-text-color;
            font-family: $default-font;
            font-size: 15px;
        }
    
        a {
            &.title {
                font-size: 28px;
                font-family: $decorative-font;
                color: $feature-nav-a-color;
                text-transform: uppercase;
                
                &:hover
                {
                    color: $feature-nav-a-color-hover;
                }
            }
    
    
            &:hover:before
            {
                background-color: rgba(0, 0, 0, 0);
            }
    
            .content
            {
                width: 100%;
            }
    
            img
            {
                max-width: 70%;
                max-height: 110px;
            }
        }
        
    }
    
    .feature-nav-icon
    {
        width: 150px;
        height: 150px;
        display: block;
        margin: 0 auto 20px;
        
        font-size: 60px;
        background-size: cover;
        
        &.icon_0
        {
            background: transparent url('/images/feature-btn-services.svg') top center no-repeat;
        }
        
        &.icon_1
        {
            background: transparent url('/images/feature-btn-about-us.svg') top center no-repeat;
        }
        
        &.icon_2
        {
            background: transparent url('/images/feature-btn-events.svg') top center no-repeat;
        }
    }
}

/* Large desktop */
@media (min-width: 1200px) {

}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {

}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
    .feature-nav .feature-nav-item
    {
        flex-basis: 50%;
        
        font-size: 24px;
    }
}

/* Landscape phone to portrait tablet */
@media (max-width: 991px) {
    .feature-nav {
        .feature-nav-item {
            text-align: center;
        
            img {
                max-width: 70%;
                max-height: 90px;
            }
        
            p {
                font-size: 13px;
                line-height: 1.5em;
                width: 100%;
            }
        }
    }
}

/* Landscape phone to portrait tablet */
@media (max-width: 768px) {
    .feature-nav
    {
        padding: 20px;
    
        .feature-nav-item
        {
            flex-basis: 100%;
            min-height: 130px;
            margin-bottom: 10px;
        
            font-size: 24px;
            text-align: center;
        }
    
        img
        {
            max-width: 70%;
            max-height: 90px;
        }
    
        p
        {
            font-size: 13px;
            line-height: 1.5em;
            width: 100%;
        }
    }
}