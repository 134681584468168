/*=========================================================================*/
/* [Header Template Styles] */

.header {
    position: relative;
    width: 100%;
    z-index: 20;
    padding: 0 0 20px 0;
    margin-bottom: -95px;
    
    .h-logo
    {
        max-width: 251px;
        
        img {
            width: 100%;
        }
    }
    
    .btn-cloud
    {
        float: right;
    }
}

/* Large desktop */
@media (min-width: 1200px) {

}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {

}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Landscape phone to portrait tablet */
@media (max-width: 991px) {
    .header
    {
        text-align: center;
        padding: 20px 0 0 0;
        margin-bottom: 20px;
    
        .h-logo img
        {
            max-width: 280px;
            margin-bottom: 10px;
        }
    
        .btn-cloud
        {
            float: none;
            display: block;
            width: 145px;
            height: 100px;
            margin: 20px auto!important;
        }
    }
    
}